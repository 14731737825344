import '../../polyfills';
import { initSubmitOnChange } from '../shared/form/submit-on-change';
import { initSwitchable } from './components/switchable';
import { initDismissable } from './components/dismissable';
import { initDropdowns } from './components/dropdown';
import { hydrateYoutubeElements } from '../../library/youtube/youtube';
import { initIncrementableInputs } from './components/incrementable-input';
import naja from 'naja';
import { LoaderExtension } from '../../library/naja/naja-spinners';
import { LiveForm, Nette } from 'live-form-validation';
import { uiBlocker } from '../../library/ui-blocker/ui-blocker';
import { initReCaptcha } from './form/recaptcha';
import 'slick-carousel';
import 'bootstrap';
import { initSentryFromWebConfig } from '../../utils/sentry';
import { displayConnectionErrorHandler } from '../../utils/xhr-display-connection-error-handler';
import { initSliders } from './components/slider';
import { initGtmEventsIfGtmActive } from './gtm/gtm';
import { initInfoBar } from './components/info-bar';
import { initUiInteractions } from './interactions/interactions';
import { initProductFilter } from './product/product-filter';
import { handleCartModalOpen, handleLoginModalFormSuccess } from './modals/modals';
import { initSmoothScroll } from './interactions/smooth-scroll';
import { initNumericInputs } from './form/numeric-input';
import { initRtsoftCookieConsent } from './components/cookie-consent';
import { getJsData } from '../../utils/js-data';
import { initScrollToTop } from '../../global-helpers';

initSentryFromWebConfig();

window.LiveForm = LiveForm;
window.Nette = Nette;
window.naja = naja;
Nette.initOnLoad();

initReCaptcha();

LiveForm.setOptions({
    messageErrorClass: 'form-input-message form-input-message--danger',
});

const initMobileSearchMenu = () => {
    document
        .getElementById('search-toggler')
        .addEventListener('click', () => document.getElementById('search-form-mobile').classList.toggle('visible'));
};

document.addEventListener('DOMContentLoaded', () => {
    // inicializueme menu

    // initMenu();

    initSmoothScroll();

    // inicializueme dropdowns
    initDropdowns();

    // inicializueme prepinatelne
    initSwitchable();

    // inicializueme prepinatelne
    initDismissable();

    // inicializujeme GTM udalosti
    initGtmEventsIfGtmActive();

    initNumericInputs();

    initScrollToTop();

    // inicializueme našeptávač

    // Našeptávač je nyní deaktivovaný
    // initWhisperer(
    //     document.getElementById('whisperer-head-wrapper'), // id formjuláře, který našeptávač spouští
    //     document.getElementById('whisperer-head-wrapper-result'), // container pro výsledky,
    //     600, // debounce
    //     3, // min chars
    //     document.getElementById('whisperer-head-wrapper').dataset.whisperQueryUrl,
    //     document.getElementById('whisperer-head-wrapper').dataset.whisperDetailUrl
    // );

    // naja.initialize.bind(naja);
    naja.registerExtension(LoaderExtension, '#loader', uiBlocker(), displayConnectionErrorHandler);

    if (naja.initialized === false) {
        naja.initialize();
    }

    naja.snippetHandler.addEventListener('afterUpdate', (event) => {
        initSwitchable('#' + event.snippet.id);
        initDropdowns('#' + event.snippet.id);
        initDismissable('#' + event.snippet.id);
        initSubmitOnChange('#' + event.snippet.id);
        initGtmEventsIfGtmActive('#' + event.snippet.id);
        initSmoothScroll('#' + event.snippet.id);
        initNumericInputs('#' + event.snippet.id);
        initIncrementableInputs('#' + event.snippet.id);

        const snippetActions = {
            'snippet--productList': initProductFilter,
            // v případě úspěšného loginu, provedeme přesměrování
            'snippet-userSignInFormHeader-loginForm': handleLoginModalFormSuccess,
            // v případě snippetu přidání do košíku, otevřeme modal
            'snippet--addToCartModal': handleCartModalOpen,
        };

        if (snippetActions[event.snippet.id]) {
            snippetActions[event.snippet.id]();
        }
    });

    // naja.init();
    // console.log(naja);

    // inicializace YT video elementů

    hydrateYoutubeElements();

    // inicializace inkrementovanych inputu pro pridani do kosiku
    initIncrementableInputs();

    // ui blocker - ukázka použití

    // setTimeout(() => {
    //     const elements = document.body.querySelectorAll('.bgimg-product-box__content-wrapper > .btn--primary')
    //     for(let i = 0; i < elements.length; i++){
    //         uiBlocker.block(elements[i])
    //     }
    //     const blockedInstance = uiBlocker.block('.bgimg-article-box__image')
    //     setTimeout(() => {
    //         blockedInstance.unblock()
    //         for(let i = 0; i < elements.length; i++){
    //             uiBlocker.unblock(elements[i])
    //         }
    //     }, 5000)
    // }, 3000)

    // zavěsíme na všechny slidery
    initSliders('.bs-slider');

    initSubmitOnChange();

    initInfoBar();

    initUiInteractions();

    const jsData = getJsData();

    initRtsoftCookieConsent(jsData.cookieconsent.language, jsData.cookieconsent.data);

    initMobileSearchMenu();
});

/* window.addEventListener('resize', function () {
    'use strict';
    window.location.reload();
}); */
